// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buchiumasanchoku-jsx": () => import("./../../../src/pages/buchiumasanchoku.jsx" /* webpackChunkName: "component---src-pages-buchiumasanchoku-jsx" */),
  "component---src-pages-business-jsx": () => import("./../../../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-companyoutline-jsx": () => import("./../../../src/pages/companyoutline.jsx" /* webpackChunkName: "component---src-pages-companyoutline-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-entry-buchiumasanchoku-jsx": () => import("./../../../src/pages/entry_buchiumasanchoku.jsx" /* webpackChunkName: "component---src-pages-entry-buchiumasanchoku-jsx" */),
  "component---src-pages-entry-js": () => import("./../../../src/pages/entry.js" /* webpackChunkName: "component---src-pages-entry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-recruit-jsx": () => import("./../../../src/pages/recruit.jsx" /* webpackChunkName: "component---src-pages-recruit-jsx" */),
  "component---src-pages-success-buchiumasanchoku-jsx": () => import("./../../../src/pages/success_buchiumasanchoku.jsx" /* webpackChunkName: "component---src-pages-success-buchiumasanchoku-jsx" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-successentry-jsx": () => import("./../../../src/pages/successentry.jsx" /* webpackChunkName: "component---src-pages-successentry-jsx" */),
  "component---src-pages-voice-jsx": () => import("./../../../src/pages/voice.jsx" /* webpackChunkName: "component---src-pages-voice-jsx" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-recruit-voice-jsx": () => import("./../../../src/templates/RecruitVoice.jsx" /* webpackChunkName: "component---src-templates-recruit-voice-jsx" */),
  "component---src-templates-single-tsx": () => import("./../../../src/templates/Single.tsx" /* webpackChunkName: "component---src-templates-single-tsx" */)
}

